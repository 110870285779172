<template>
    <div>
        <b-card title="Liindr admin🚀">
            <b-card-text>Administración de Liindr App</b-card-text>
        </b-card>
    </div>
</template>

<script>
import {BCard, BCardText} from 'bootstrap-vue'

export default {
    components: {
        BCard,
        BCardText,
    },
}
</script>

<style>

</style>
